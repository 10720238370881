<template>
  <div class="client-manager">
    <els-bread class="_bread"></els-bread>
    <div class="_tip client-select">
        <el-select v-model="value" :placeholder="lang.selSourceType">
          <el-option
            v-for="(item, ins) in options"
            :key="ins"
            :label="item.type"
            :value="item.type"
          >
          </el-option>
        </el-select>
        <!-- <span class="client-line"></span> -->
        <div class="client-s">
          <span>{{ lang.totalAssets }}</span>
          <span class="num">{{ total }}</span>
        </div>
      </div>
      <div class="_header client-index">
        <span>
          <el-button size="small" type="primary" @click="add">{{lang.addAss}}</el-button>
        </span>
        <span>
          <el-input
            v-model="sourceValue"
            size="small"
            :placeholder="lang.assNumName"
            prefix-icon="el-icon-search"
          ></el-input>
        </span>
      </div>
    <div class="_content has-tip">
        <els-table
          ref="elstable"
          :column="column"
          :onload="onload"
          :table="{ rowKey: 'id.id' }"
          :menuWidth="'120px'"
        >
          <template v-slot:menu="{ row }">
            <span @click="rowView(row)" style="color: #409eff">{{lang.view}}</span>
            <span class="client-line"></span>
            <span
              @click="rowDelete(row)"
              :style="{ color: row.sourceType === '1' ? '#dddddd' : 'red' }"
              >{{ lang.delete }}</span
            >
          </template>
        </els-table>
      </div>
    <client-build
      ref="sourceDialog"
      :clientColumn="clientColumn"
      :data="data"
      @setSuccess="setOk"
    ></client-build>
    <client-drawer
      ref="sourceDrawer"
      :data="sourceData"
      :tabs="tabs"
      @isClose="setOk"
    ></client-drawer>
  </div>
</template>
<script>
import mixinsDicts from "@/mixins/dict.js";
import elsTable from "../../components/els-table/main.vue";
import clientBuild from "../components/clientManage/client-build.vue";
import clientDrawer from "../components/clientManage/client-drawer/main.vue";
import { getSource } from "@/api/base/client";
import elsBread from "../../components/els-breadcrumb/main.vue";
import { mapState } from "vuex";
import { timesort } from "../../utils/client";
import { delAsset, getIndustry } from "../../api/base/client";
import mixinsI18n from "@/mixins/i18n.js";
export default {
  mixins: [mixinsDicts, mixinsI18n],
  components: { elsTable, clientBuild, clientDrawer, elsBread },
  data: () => {
    return {
      PAGE_NAME: "sourceManagePage",
      dicts: {
        SYSTEM_SOURCETYPE: [],
      },
      options: [],
      data: { title: "", label: 0, industry: [] },
      sourceValue: "",
      total: 0,
      sourceData: {},
      firstIndustry: [],
      instry: [],
      secondIndustry: [],
      columnBread: [],
    };
  },
  computed: {
    ...mapState(["breadMenus"]),
    column() {
      const code = { prop: "code", label: this.lang.assNum, minWidth: 180 };
      const name = { prop: "name", label: this.lang.assName, minWidth: 180 };
      const type = { prop: "type", label: this.lang.assType, minWidth: 180 };
      // const clientCode = {prop:'clientCode',label:'设备编号',valueFilter:() => 'aaaaaaaaaaaaaa'}
      const additionalInfo = {
        prop: "additionalInfo",
        label: this.lang.describe,
        minWidth: 100,
      };
      const sourceType = {
        prop: "sourceType",
        label: this.lang.source,
        dict: this.dicts.SYSTEM_SOURCETYPE,
        minWidth: 50,
      };
      const createdTime = {
        prop: "createdTime",
        label: this.lang.createTime,
        valueType: "date",
        minWidth: 160,
      };
      return [code, name, type, additionalInfo, sourceType, createdTime];
    },
    clientColumn() {
      const tab = [
        {
          tag: "div",
          title: this.lang.basicPro,
          cls: [
            {
              label: this.lang.assNum,
              tag: "el-input",
              prop: "code",
              required: true,
            },
            {
              label: this.lang.assName,
              tag: "el-input",
              prop: "name",
            },
            {
              label: this.lang.assType,
              tag: "el-input",
              prop: "type",
              required: true,
              rules: [{ max: 20, message: this.lang.max20, trigger: "blur" }],
            },
            {
              tag: "el-select",
              label: this.lang.inCate,
              placeholder: this.lang.pleaseSelect,
              required: true,
              trigger: "change",
              prop: "firstLevelIndustry",
              cls: this.firstIndustry,
            },
            {
              tag: "el-select",
              label: this.lang.inSecCate,
              placeholder: this.lang.pleaseSelect,
              required: true,
              trigger: "change",
              prop: "secondLevelIndustry",
              cls: this.secondIndustry,
            },
            {
              label: this.lang.describe,
              tag: "el-input",
              prop: "additionalInfo",
              type: "textarea",
              rules: [{ max: 50, message: this.lang.max50, trigger: "blur" }],
            },
          ],
        },
      ];
      return tab;
    },
    value() {
      return this.lang.allAssType;
    },
    tabs() {
      const data = [
        { label: this.lang.detInfor, name: "1" },
        { label: this.lang.attribute, name: "2" },
        { label: this.lang.latestTel, name: "3" },
        { label: this.lang.warning, name: "4" },
        { label: this.lang.event, name: "5" },
        { label: this.lang.relation, name: "6" },
        { label: this.lang.auditLog, name: "7" },
      ];
      return data;
    },
  },
  watch: {
    value(v) {
      setTimeout(() => {
        if (v) this.$refs.elstable.apiOnload();
      }, 800);
    },
    sourceValue(v) {
      const d = v === "" ? "0" : v;
      if (d) this.$refs.elstable.apiOnload();
    },
  },
  created() {
    this.getInfo(); // 获取工业类型
  },
  mounted() {
    // 初始化获取所有资产
    this.getAsset();
    this.columnBread = this.breadMenus;
    this.$socket.connect(); //websocket
    this.initDict();
  },
  methods: {
    async getInfo() {
      const data = await getIndustry({ assetName: "" });
      this.instry = data;
      data.forEach((item) => {
        if (!!item.fatherNode || item.industryLevel === "1") {
          this.secondIndustry.push({
            id: item.id,
            fatherNode: item.fatherNode,
            level: item.industryLevel,
            label: item.industryName,
            value: item.id.id,
          });
        } else {
          this.firstIndustry.push({
            id: item.id,
            fatherNode: item.fatherNode,
            level: item.industryLevel,
            label: item.industryName,
            value: item.id.id,
          });
        }
      });
    },
    async initDict() {
      try {
        Object.keys(this.dicts).forEach(async (k) => {
          if (k != "SYSTEM_COMPANY_STATUS" && k != "SYSTEM_SOURCETYPE") {
            const res = await getDict(k);
            if (res) {
              this.dicts[k] = res.map((item) => ({
                label: item.name,
                value: item.type + "_" + item.value,
              }));
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async onload(page, query = {}) {
      try {
        const pn = page.pageNum;
        const ps = page.pageSize;
        const row = page.row;
        const p = {
          limit: ps,
          type: this.value === this.lang.allAssType ? "" : this.value,
          textSearch: this.sourceValue,
          pageNum: pn,
        };
        // if (pn > 1 && row) {
        //   p.idOffset = row.id;
        // }
        const res = await getSource(p);

        let list = [];
        if (res.data) {
          if (!!this.sourceValue && this.sourceValue === "_") {
            list = res.data.filter((item) => {
              return (
                String(item.name).indexOf(this.sourceValue) > -1 ||
                String(item.code).indexOf(this.sourceValue) > -1
              );
            });
            this.total = list.length;
          } else {
            list = res.data;
            this.total = res.totalCount || res.data.length;
          }
        }
        let reg = /[`~!@#$%^&*()+<>?:"{},.\/;'[\]]/im;
        const testValue = reg.test(this.sourceValue);
        return {
          list: testValue
            ? []
            : list.map((item) => ({
                ...item,
                id: item.id.id,
                nId: item.id,
                sourceId: item.id,
                desc: item.additionalInfo
                  ? item.additionalInfo.description
                  : "",
              })),
          total: testValue ? 0 : this.total,
        };
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    setOk() {
      this.getAsset(); // 新增资产成功，重新查询资产类型
      this.$refs.elstable.apiOnload();
    },
    async getAsset() {
      const d = {
        type: this.value === this.lang.allAssType ? "" : this.value,
        textSearch: this.sourceValue,
        limit: 40,
      };
      const data = await getSource({ ...d });
      const arr = data.data;
      const keyArr = [];
      arr.forEach((element, index) => {
        keyArr.push(element.type);
      });
      const newArr = [];
      const newType = new Set(keyArr);
      newType.forEach((type) => {
        const index = keyArr.findIndex((item2) => item2 === type);
        newArr.push(arr[index]);
      });
      this.options = newArr;
      this.options.unshift({
        type: this.lang.allAssType,
        id: {
          id: "",
        },
      });
    },
    getData() {
      const d = {
        type: this.value === this.lang.allAssType ? "" : this.value,
        textSearch: this.sourceValue,
        limit: 40,
      };
      let data = getSource(d).then((res) => {
        return res;
      });
      return data;
    },
    add() {
      this.data.title = this.lang.addAss;
      this.data.label = "1";
      this.data.industry = this.instry;
      this.$refs.sourceDialog.dialogVisible = true;
    },
    // 查看资产
    rowView(row) {
      this.$refs.sourceDrawer.drawer = true;
      this.sourceData = row;
      this.sourceData.sign = "2";
    },
    rowDelete(row) {
      if (row.sourceType === "1") return;
      this.$confirm(this.lang.delMsg, this.lang.tips, {
        confirmButtonText: this.lang.confirm,
        cancelButtonText: this.lang.cancel,
        type: "warning",
      }).then(() => {
        delAsset(row.id).then((res) => {
          this.$message({
            type: "success",
            message: this.lang.delSuccess,
          });
          this.$refs.elstable.apiOnload();
          this.getAsset();
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.client-manager {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .client-select {
    padding: 0px 24px 0px 24px;
    display: flex;
    align-items: center;
    .client-s {
      flex: 1;
      padding: 6px 0px;
      padding-left: 10%;
      background: #fff;
      margin-left: 30px;
      color: #4c4c4c;
      .num {
        display: inline-block;
        margin-left: 10px;
        font-size: 18px;
      }
    }
  }
  .client-index {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
    }
    .client-line {
      margin: 0px 8px;
      display: inline-block;
      width: 1px;
      height: 12px;
      background: #ddd;
    }
}
</style>
